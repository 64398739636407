/**
 * Initialise Dash UGC Blocks
 *
 * This function must be called on pages that include Dash UGC
 * content blocks, but no more than once per page load.
 */
export const initDashUgcBlocks = () => {
  // Add an IntersectionObserver to track impressions when a Dash UGC
  // carousel block becomes visible in the viewport.
  const observer = new IntersectionObserver((entries) => {
    for (const entry of entries) {
      if (!entry.isIntersecting) {
        continue;
      }

      // Unobserve, so we only record impressions once per block.
      observer.unobserve(entry.target);

      const widget = entry.target.querySelector('.dash-ugc-widget');

      const script = document.createElement('script');

      script.type= 'text/javascript';
      script.src = 'https://cdn.dashhudson.com/web/js/board-carousel-embed.js';

      script.dataset.name = 'dhboard-carousel' 
      script.dataset.galleryId = widget.dataset.candidId;
      script.dataset.rowSize = 6;
      script.dataset.gapSize = 4;
      script.dataset.rowLimit = 1
      script.dataset.mobileRowSize = 2
      script.dataset.mobileGapSize = 2;
      script.dataset.autoplay = 'true';
      script.dataset.lazy = 'true';
      script.dataset.carouselDots = 'true';

      const viewport = new Viewport(MV.breakpoints);
      const rowSize = viewport.matches('mobile') ? script.dataset.mobileRowSize : script.dataset.rowSize;
      const gapSize = viewport.matches('mobile') ? script.dataset.mobileGapSize : script.dataset.gapSize;

      const height = Math.round((window.innerWidth - (gapSize * rowSize)) / rowSize);
      const placeholder = entry.target.querySelector('.dash-ugc-widget-placeholder');
      placeholder.style.height = `${height}px`;

      const container = entry.target.querySelector('.dash-ugc-widget-container');
      container.style.height = `${height}px`;

      const mutationObserver = new MutationObserver((records) => {
        for (const record of records) {
          for (const addedNode of record.addedNodes) {
            if (addedNode.classList.contains('slick-list')) {
              container.style.height = '';
              placeholder.remove();
              mutationObserver.disconnect();
            }
          }
        }
      });
      mutationObserver.observe(widget, { childList: true, subtree: true });

      widget.appendChild(script);

      observer.disconnect();
    }
  }, { rootMargin: `${window.innerHeight / 2}px 0px 0px 0px` });

  document.querySelectorAll('.block--dash-ugc').forEach((block) => {
    observer.observe(block);
  });
};

